.appicon {
    height: 64;
    width: 64;
    border: 0;
    margin: 8px;
}

.versionWrapper {
    margin-left: 8px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
    color: white;
    background-color: #2f992f;
    border-radius: 5px;
    padding: 4px 8px;
    height: 22px;
}

.version {
    display: flex;
    
}

.build {
    margin-left: 12px;    
    
}

.name {
    font-size: 20px;
    display: flex;
    align-items: end;
    font-weight: 500;
}

.profileContainer {
    cursor: auto;
    background: rgb(220,220,220);
    min-height: 40px;
    padding: 4px;
    background: linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(245,245,245,1) 48%, rgba(194,194,194,1) 100%);
}

.profileContent {
    font-family: "Montserrat";
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.profileItem {
    margin: 1px 0 1px 5px; 

    padding: 2px 6px 2px 6px;
    border-radius: 5px;
    background-color: red;
    color: white;
    font-weight: 500;
    cursor: pointer;
}

.profileItem:hover {
    filter: brightness(90%);
}

