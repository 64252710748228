.hbox {
    display: flex;
}

.itemIcon {
    padding: 0 16px 0 0;
}

.itemContainer {
    display: flex;
    justify-content: center;
    cursor: auto;
    background: rgb(220,220,220);
    padding: 8px;
    background: linear-gradient(90deg, rgba(220,220,220,1) 0%, rgba(245,245,245,1) 48%, rgba(194,194,194,1) 100%);
}

.itemAction {
    cursor: pointer !important;
    border: 0;
}

:global(.Mui-selected).itemAction {
    color: white;
    background-color: rgb(156, 39, 176) !important;
}