.wrapper {
    display: flex;
    width: 100vw;
    /*overflow: auto;*/
    max-height: 100vh;
}

.contentWrapper {
    flex-grow: 1;

    background-color: #f1f1f1;
    overflow: hidden;
    padding: 8px;
    
    display: flex;
    flex-direction: column;
}

.content {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
}