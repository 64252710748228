.wrapper {
  display: flex;
  align-items: stretch;
  width: 500px;
}

.type, .value {
  flex-grow: 1;
  display: flex;
  width: 50%;
}

.type {
  margin-right: 4px;
}

.value {
  margin: 0 4px;
}

.sort {
  margin-left: 4px;
  padding: 0 4px; 
}

.sort :global(.MuiIconButton-root) {
  font-size: 20px;
}

.selectfield {
  width: 100%;
}

.selectfield, .selectfield :global(.MuiSelect-icon) {
  color: inherit;
}

.selectfield::before, .selectfield::after, .selectfield:hover, 
  .selectfield:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: unset;
}



.selectfield :global(.MuiInput-input:focus)  {
  background-color: transparent;
}

.placeholder {
  color: #7bb1e3;
  font-weight: 500;
}