div {
    box-sizing: border-box; /* Границы и отступы не увеличивают размеры блоков */
    overflow-x: hidden;
    white-space: nowrap;
}

.wrapper {
    display: flex;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    width: 100vw;
    max-height: 100vh;
}

.left, .right {
    flex-grow: 1;
    display: flex;
    overflow-x: hidden;
    width: 50%;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.page-content {
    display: flex;
    flex-direction: row;
}

.pagelist {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.pagelist div {
    text-overflow: ellipsis;
}

.pagelist .pagelist-body {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.pagelist .pagelist-item .MuiButtonBase-root:hover {
    cursor: auto;
    background-color: unset;
}

.pagelist .pagelist-body .MuiButtonBase-root.Mui-selected {
    background-color: unset;
}

.pagelist .pagelist-body .MuiButtonBase-root:hover.Mui-selected {
    background-color: unset;
}

.pagelist .pagelist-body .MuiButtonBase-root .MuiCard-root:hover {
    background-color: rgb(248, 248, 238);
}

.pagelist .pagelist-body .MuiButtonBase-root.Mui-selected .MuiCard-root {
    background-color: aliceblue;
}

.pagelist .pagelist-body .MuiListItemButton-root .MuiCard-root {
    border-radius: 8px;
}

.pagelist-details {
    height: 100%;
    padding-top: 8px;
    margin-left: 8px;
    flex-grow: 1;   
}

.menu-icon {
    padding: 0 8px 0 0;
}

.tgitem-enter {
    width: 0;
}

.tgitem-enter-active {
    width: 50%;
    transition: width 300ms ease;
}

.text-icon {
    width: 32px;
    height: 24px;
    padding-right: 8px;
}

.toolbar {
    display: flex;
    justify-content: center;
}

.auth-form {
    display: flex;
    justify-content: center;
    padding: 30px;
}