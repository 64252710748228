.paper {
    padding: 20px;
    width: 350px;
    margin: auto auto;
    display: flex;
    flex-direction: column;
}

.avatar {
    background-color: #1bbd7e;
}

.username {
    margin: 10px 0;
}

.margin5y {
    margin-top: 5px;
    margin-bottom: 5px;
}