
:global .ps-menu-icon {
    width: 35px;
    min-width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 10px;
    color: rgb(89, 208, 255);
}

:global .ps-menu-button:hover {
    background-color: rgb(0, 69, 139) !important;
    color: rgb(182, 200, 217) !important;
}

:global .ps-menu-button {
    border-radius: 8px;
    border-width: 1px;
    margin: 2px 4px;
}

:global .ps-menu-button.active {
    border-style: solid;
    border-color: #f1f1f1;
}

:global .ps-submenu-expand-icon {
    color: rgb(182, 183, 185);
}

.wrapper h1 {
    color: rgb(210, 230, 250);
    font-size: 20px;
}