.header {
    background-color: rgb(0, 69, 139);
    display: flex;
    justify-content: center;
}
.appbar {
    border-radius: 8px;
}

.toolbar {
    display: flex;
    justify-content: space-between;
}

.body {
    display: flex;
    align-items: center;
}

